body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.PhoneInputInput {
  height: 1.4375em;
  outline-color: #1565c0;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 8.5px 14px;
  font: inherit;
  margin: -1px;
}

.PhoneInputInput:hover {
  border: 1px solid black;
}
