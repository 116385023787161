input::placeholder {
    color: rgba(0, 0, 0, 0.4);
}

.PhoneInputInput {
    padding-left: 60px !important;
}

.PhoneInput {
    position: relative;
}

.PhoneInputCountry {
    position: absolute;
    height: 100%;
    left: 14px;
}

