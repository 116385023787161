.customPhoneInput input {
    height: 25.39px !important;
    border: 1px solid #e6e6e6;
    transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}

.customPhoneInput input:focus {
    border-color: var(--p-colorPrimaryHover);
    box-shadow:     0 0 0 3px var(--p-colorPrimaryAlpha20),
    0 0 0 0px var(--p-colorPrimaryActive),
    2px 2px 4px rgba(0, 0, 0, 0.1);
    outline: 1px solid var(--p-colorPrimaryActive);
    borderColor: var(--p-colorPrimaryHover),
}

.customPhoneInputError input:focus {
    border-color: var(--p-colorPrimaryHover);
    box-shadow:     0 0 0 3px var(--p-colorPrimaryAlpha20),
    0 0 0 0px var(--p-colorPrimaryActive),
    2px 2px 4px rgba(0, 0, 0, 0.1);
    outline: 1px solid var(--p-colorPrimaryActive);
    borderColor: var(--p-colorPrimaryHover),
}

.customPhoneInputError input{
    border: 2px solid var(--stripe-danger); /* Change this color to match your theme */
}

.customPhoneInput.customPhoneInputError input:hover{
    border: 2px solid var(--stripe-danger); /* Change this color to match your theme */
}

.customPhoneInput input:hover {
    border-color: #e6e6e6;
}

.customPhoneInput input:focus:hover {
    border-color: var(--p-colorPrimaryHover);
}

:root {
    --p-colorPrimaryActive: #82B9F0;
    --p-colorPrimaryHover: rgba(130, 185, 240, 0.8);
    --p-colorPrimaryAlpha20: hsla(210, 96%, 45%, 25%);
    --p-colorPrimaryAlpha10: hsla(210, 96%, 45%, 10%);
    --p-colorPrimaryAlpha40: hsla(210, 96%, 45%, 40%);
    --p-colorPrimaryAlpha50: hsla(210, 96%, 45%, 50%);
    --app-color: #fafafa;
    --stripe-danger: #df1b41;
    --stripeFontFamily: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

#root {
    background: var(--app-color);
    min-height: 100vh;
}

#root.black-bg {
    background: #0d0d0d;
}

.coupon {
    background-color: var(--app-color);
}

/*.Mui-error input:focus {*/
/*    border-color: green;*/
/*    box-shadow:     0 0 0 3px var(--p-colorPrimaryAlpha20),*/
/*    0 0 0 0px var(--p-colorPrimaryActive),*/
/*    2px 2px 4px rgba(0, 0, 0, 0.1);*/
/*    outline: 1px solid var(--p-colorPrimaryActive);*/
/*    borderColor: green,*/
/*}*/